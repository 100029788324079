import { PAYMENT_TYPES } from '@/domains/Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import { shallowMount } from '@vue/test-utils';
import ListPaymentMethodsItem from '@/domains/Shared/Payments/PaymentMethodsHub/ListPaymentMethodsItem';

const MOCK_PAYMENT_METHOD = {
  uuid: 'a80018a3-5fa6-4a1f-9335-a9e7ab105ae8',
  reference: 'SU6579c71690400',
  type: 'visa',
  number: '1111',
  expiry: '03/30',
  holder: 'CARD TEST',
  active: true,
  status: 'active',
  internal: false,
  secured_request_data: null,
  secured_issuer_url: null,
  secured_session: null,
  payment_method_type: 'card',
  userProfile: [
    {
      uuid: 'd5430c79-17d7-474a-8c35-38bbd5968115',
    },
  ],
};

let wrapper;

describe('Given a PaymentMethodsItem', () => {
  wrapper = shallowMount(ListPaymentMethodsItem, {
    propsData: {
      index: 0,
      paymentMethod: MOCK_PAYMENT_METHOD,
      isLoading: false,
      isOpen: false,
      defaultPaymentMethodKey: 0,
      defaultPaymentMethodUuid: 'PaymentUUID',
      defaultOppositePaymentMethodUuid: 'PaymentUUID',
    },
  });
  describe('When the "formatPaymentMethod" is called with the bank transfer type', () => {
    it('Then must return "Bank transfer"', async () => {
      const result = wrapper.vm.formatPaymentMethod(PAYMENT_TYPES.bankTransfer, '');

      expect(result).toBe('common.payment_method.bank_transfer');
    });
  });

  describe('When the "formatPaymentMethod" is called with the IBAN "ESXX0123456789ABCDEF"', () => {
    it('Then must return "ESXX **** **** **** CDEF"', async () => {
      const result = wrapper.vm.formatPaymentMethod(PAYMENT_TYPES.iban, 'ESXX0123456789ABCDEF');

      expect(result).toBe('ESXX************CDEF');
    });
  });

  describe('When the "formatPaymentMethod" is called with the credit card "0123456789ABCDEF"', () => {
    it('Then must return "0123 4567 89AB CDEF"', async () => {
      const result = wrapper.vm.formatPaymentMethod(PAYMENT_TYPES.card, '0123456789ABCDEF');

      expect(result).toBe('**** **** **** CDEF');
    });
  });
});
